import Navbar from 'react-bootstrap/Navbar';
import { images } from '../assets/images';

export const Header = () => {
  return (
    <Navbar fixed="top" className="App-header">
      <Navbar.Brand href="#">
        <img src={images.logo} alt="logo" />
      </Navbar.Brand>
      <Navbar.Text>Post Builder</Navbar.Text>
    </Navbar>
  );
};
