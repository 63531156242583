import Form from 'react-bootstrap/Form';
import { FormEntity, FormEntityKeys } from '../../data/types';
import { ChangeEvent } from 'react';
import { DEBUG_AUTOFILL, MockEntity } from '../../data/data';
import { useSettings } from '../../contexts/SettingsContext';

export const FormContent = () => {
  //@ts-ignore
  const defaultValue: FormEntity = DEBUG_AUTOFILL
    ? MockEntity
    : { lang: 'es', date: new Date() };
  const { getActiveModel, form, setForm } = useSettings();
  const model = getActiveModel();

  const setFormState = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const key = target.name;
    setForm({ ...form, [key]: value });
  };

  const setFormLang =
    (lang: 'en' | 'es' | 'fr' | 'de') =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, lang });
    };

  const setFormImage = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const key = target.name;
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > 1024 * 1024)
        alert(
          `Your file is too heavy, max 1MB (size = ${formatFileSize(
            file.size
          )})`
        );
    }

    setForm({ ...form, [key]: file });
  };

  const getVisibleFieldsByModel = (): FormEntityKeys[] => {
    if (model) {
      if (model.name === 'Congress') {
        return ['lang', 'date', 'title', 'description'];
      } else if (model.name === 'Congress Presentation') {
        return [
          'lang',
          'date',
          'title',
          'description',
          'speakerName',
          'speakerPhoto',
          'speakerPosition',
        ];
      } else if (model.name === 'Photo frame at congress') {
        return ['photoFrame'];
      } else if (model.name === 'Sympossium / Own meeting') {
        return ['lang', 'date', 'title', 'description'];
      } else if (model.name === 'Clinical Case Publication') {
        return ['lang', 'title', 'description'];
      } else if (model.name === 'Text + photo (new product, web...)') {
        return ['title', 'description', 'photoFrame'];
      }
    }
    return [];
  };

  const formatFileSize = (bytes: number) => {
    const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
  };

  const visibleFields = getVisibleFieldsByModel();

  if (visibleFields.length === 0) {
    return <span className="emptyState">Please select a Model</span>;
  }

  return (
    <Form>
      {visibleFields.includes('lang') ? (
        <div className="mb-3">
          <Form.Label>Language</Form.Label>

          <Form.Check
            label="English"
            name="lang"
            type="radio"
            id="en"
            defaultChecked={defaultValue.lang === 'en'}
            onChange={setFormLang('en')}
          />
          <Form.Check
            label="Spanish"
            name="lang"
            type="radio"
            id="es"
            defaultChecked={defaultValue.lang === 'es'}
            onChange={setFormLang('es')}
          />
          <Form.Check
            label="German"
            name="lang"
            type="radio"
            id="de"
            defaultChecked={defaultValue.lang === 'de'}
            onChange={setFormLang('de')}
          />
          <Form.Check
            label="French"
            name="lang"
            type="radio"
            id="fr"
            defaultChecked={defaultValue.lang === 'fr'}
            onChange={setFormLang('fr')}
          />
        </div>
      ) : (
        <></>
      )}

      {visibleFields.includes('date') ? (
        <Form.Group className="mb-3" controlId="formDate">
          <Form.Label>Date</Form.Label>
          <Form.Control
            name="date"
            type="date"
            defaultValue={defaultValue.date?.toLocaleDateString('en-CA')}
            onChange={setFormState}
          />
        </Form.Group>
      ) : (
        <> </>
      )}

      {visibleFields.includes('title') ? (
        <Form.Group className="mb-3" controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            maxLength={45}
            defaultValue={defaultValue.title}
            onChange={setFormState}
          />
          <Form.Text className="text-muted">45 characters max.</Form.Text>
        </Form.Group>
      ) : (
        <> </>
      )}

      {visibleFields.includes('description') ? (
        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            name="description"
            as="textarea"
            maxLength={160}
            defaultValue={defaultValue.description}
            onChange={setFormState}
          />
          <Form.Text className="text-muted">160 characters max.</Form.Text>
        </Form.Group>
      ) : (
        <> </>
      )}

      {visibleFields.includes('photoFrame') ? (
        <Form.Group className="mb-3" controlId="formPhotoFrame">
          <Form.Label>Photo frame</Form.Label>
          <Form.Control name="photoFrame" type="file" onChange={setFormImage} />
          <Form.Text className="text-muted">
            Max. 1MB, Landscape (Model "Photo frame at congress": 980*480 px
            recommended, Model "Text + photo": 580*450 px recommended)
          </Form.Text>
        </Form.Group>
      ) : (
        <> </>
      )}

      {visibleFields.includes('speakerName') ? (
        <Form.Group className="mb-3" controlId="formSpeakerName">
          <Form.Label>Speaker name</Form.Label>
          <Form.Control
            name="speakerName"
            maxLength={45}
            defaultValue={defaultValue.speakerName}
            onChange={setFormState}
          />
          <Form.Text className="text-muted">45 characters max.</Form.Text>
        </Form.Group>
      ) : (
        <> </>
      )}

      {visibleFields.includes('speakerPhoto') ? (
        <Form.Group className="mb-3" controlId="formSpeakerPhoto">
          <Form.Label>Speaker photo</Form.Label>
          <Form.Control
            name="speakerPhoto"
            type="file"
            onChange={setFormImage}
          />
          <Form.Text className="text-muted">
            Max. 1MB. Square (Model "Congress Presentation": 300*300 px
            recommended)
          </Form.Text>
        </Form.Group>
      ) : (
        <> </>
      )}

      {visibleFields.includes('speakerPosition') ? (
        <Form.Group className="mb-3" controlId="formSpeakerPosition">
          <Form.Label>Professional position</Form.Label>
          <Form.Control
            name="speakerPosition"
            maxLength={45}
            defaultValue={defaultValue.speakerPosition}
            onChange={setFormState}
          />
          <Form.Text className="text-muted">45 characters max.</Form.Text>
        </Form.Group>
      ) : (
        <> </>
      )}
    </Form>
  );
};
