import { useSettings } from '../../contexts/SettingsContext';
import { AreaEntity, ModelEntity } from '../../data/types';

import { getArea, getProductModels } from '../../data/utils';

export const ModelSelector = () => {
  const { area, product, model, setModel } = useSettings();

  const activeArea = area ? getArea(area) : undefined;
  const models = area ? getProductModels(area, product) : [];

  if (activeArea && models.length) {
    return (
      <div className="model-list">
        {models.map((item, index) => (
          <Element
            key={item.name}
            model={item}
            isPressed={model === item.name}
            onPress={() => setModel(item.name)}
            activeArea={activeArea}
            product={product}
          />
        ))}
      </div>
    );
  }
  return <span className="emptyState">Please select a Product</span>;
};

const Element = ({
  model,
  isPressed,
  onPress,
  activeArea,
  product,
}: {
  model: ModelEntity;
  isPressed: boolean;
  onPress: () => void;
  activeArea: AreaEntity;
  product?: string;
}) => {
  const color = isPressed ? activeArea.color : undefined;
  const thumbnail =
    (product ? model.thumbnails[product] : undefined) ??
    model.thumbnails['default'];

  return (
    <label className={`product ${isPressed && 'active'}`} onClick={onPress}>
      <img src={thumbnail} alt={model.name} />
      <span style={{ color }}>{model.name}</span>
      <div />
    </label>
  );
};
