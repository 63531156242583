import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export const usePDF = () => {
  const buildPdfCanvas = async () => {
    const ratio = 1020 / 630;
    const width_mm = 297;

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [297, width_mm / ratio],
    });

    const target = document.getElementById('canvas_comp');
    if (!target) return;

    const canvas = await html2canvas(target);
    const image = canvas
      .toDataURL('image/jpeg', 1.0)
      .replace('image/png', 'image/octet-stream');

    var pageSize = doc.internal.pageSize;
    doc.addImage(image, 'JPG', 0, 0, pageSize.width, pageSize.height);

    return doc;
  };
  const openPDF = async () => {
    const doc = await buildPdfCanvas();
    if (doc) window.open(URL.createObjectURL(doc.output('blob')));
  };
  const savePDF = async () => {};

  return { openPDF, savePDF };
};
