import { images } from '../../assets/images';
import { useSettings } from '../../contexts/SettingsContext';
import { getProductModels } from '../../data/utils';
import { Canvas } from './Canvas';

export const Preview = () => {
  const { area, product, model, form } = useSettings();
  const getActiveModel = () => {
    if (area && model) {
      const models = getProductModels(area, product);

      const activeModel = models.find((item) => item.name === model);
      return activeModel;
    }
    return undefined;
  };
  const modelEntity = getActiveModel();
  const imageForModel =
    (product ? modelEntity?.thumbnails[product] : undefined) ??
    modelEntity?.thumbnails['default'];

  const thumbnail = imageForModel ?? images.modelEmpty;

  const isPreviewFilled =
    form.title ||
    form.description ||
    form.photoFrame ||
    form.speakerPhoto ||
    form.speakerName ||
    form.speakerPosition;

  return (
    <div className="preview">
      {isPreviewFilled && modelEntity ? (
        <Canvas isThumbnailPreview />
      ) : (
        <img className="image" src={thumbnail} alt={modelEntity?.name} />
      )}

      <span>Image to download (1020 * 630 px)</span>
    </div>
  );
};
