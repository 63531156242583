import html2canvas from 'html2canvas';
import { useSettings } from '../../contexts/SettingsContext';

export const useCanvas = () => {
  const { getActiveModel } = useSettings();
  const model = getActiveModel();

  const downloadImage = (dataUrl: any) => {
    var link = document.createElement('a');
    link.download = `${model?.name}.jpg`;
    link.href = dataUrl;
    link.click();
  };

  const saveCanvas = async () => {
    const target = document.getElementById('canvas_comp');
    if (target) {
      const canvas = await html2canvas(target, { width: 1020, height: 630 });
      const image = canvas
        .toDataURL('image/jpeg', 1.0)
        .replace('image/png', 'image/octet-stream');
      downloadImage(image);
    }
  };

  return { saveCanvas };
};
