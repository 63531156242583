import Navbar from 'react-bootstrap/Navbar';
import { images } from '../assets/images';

export const Footer = () => {
  return (
    <Navbar fixed="bottom" className="App-footer">
      <Navbar.Brand href="#">
        <img src={images.logo} alt="logo" />
      </Navbar.Brand>
    </Navbar>
  );
};
