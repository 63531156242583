import { useSettings } from '../../contexts/SettingsContext';
import { AreaEntity, ProductEntity } from '../../data/types';

import { getArea, getAreaProducts } from '../../data/utils';

export const ProductSelector = () => {
  const { area, product, setProduct } = useSettings();

  const activeArea = area ? getArea(area) : undefined;
  const products = area ? getAreaProducts(area) : [];

  if (activeArea) {
    return (
      <div className="product-list">
        {products.map((item) => (
          <Element
            key={item.name}
            product={item}
            isPressed={product === item.name}
            onPress={() => setProduct(item.name)}
            area={activeArea}
          />
        ))}
      </div>
    );
  }
  return <span className="emptyState">Please select an Area</span>;
};

const Element = ({
  product,
  isPressed,
  onPress,
  area,
}: {
  product: ProductEntity;
  isPressed: boolean;
  onPress: () => void;
  area: AreaEntity;
}) => {
  const color = isPressed ? area.color : undefined;

  return (
    <label className={`product ${isPressed && 'active'}`} onClick={onPress}>
      <img
        src={product.thumbnail}
        alt="product"
        style={{ borderColor: color }}
      />
      <span style={{ color }}>{product.name}</span>
      <div />
    </label>
  );
};
