import { useState } from 'react';
import { useSettings } from '../../contexts/SettingsContext';

export const Canvas = ({
  isThumbnailPreview = false,
}: {
  isThumbnailPreview?: boolean;
}) => {
  const { form, getActiveModel, product } = useSettings();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const modelEntity = getActiveModel();
  if (!modelEntity) return <></>;

  const getFormattedDate = (withWeekday = false, uppercaseFirst = false) => {
    const format: Record<string, string> = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    if (withWeekday) {
      format['weekday'] = 'long';
    }
    const ret = new Date(form.date).toLocaleDateString(form.lang, format);
    if (!uppercaseFirst) return ret;
    return capitalize(ret);
  };

  const capitalize = (s: string) =>
    s.substring(0, 1).toUpperCase() + s.substring(1);

  const getDayOfWeek = (withWeekday = false) => {
    const result = new Date(form.date).toLocaleDateString(form.lang, {
      weekday: 'long',
    });
    return capitalize(result);
  };

  const ContentCongress = () => {
    const left = '562px',
      width = '400px';
    return (
      <div className="content">
        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '54px',
            color: 'white',

            position: 'absolute',
            top: '141px',
            left,
            width,
            textAlign: 'left',
            lineHeight: 'normal',
          }}
        >
          {form.title}

          <p
            style={{
              fontFamily: 'Aeroportal-Medium',
              fontSize: '32px',
              color: 'white',
              width,
              textAlign: 'left',
              lineHeight: '34px',
              marginTop: '20px',
              opacity: 0.8,
            }}
          >
            {getFormattedDate(true, true)}
          </p>

          <p
            style={{
              fontFamily: 'Aeroportal-Medium',
              fontSize: '21px',
              color: 'white',

              position: 'absolute',
              width,
              textAlign: 'left',
              lineHeight: '24px',
              marginTop: '20px',

              opacity: 0.8,
            }}
          >
            {form.description}
          </p>
        </p>
      </div>
    );
  };

  const ContentCongressPresentation = () => {
    const speakerText = {
      es: 'Ponente',
      en: 'Speaker',
      de: 'Redner',
      fr: 'Conférencier',
    }[form.lang];

    return (
      <div className="content">
        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '56px',
            color: 'white',

            position: 'absolute',
            top: '45px',
            left: '55px',
            width: '380px',
            height: '400px',
            textAlign: 'left',
            lineHeight: 'normal',
          }}
        >
          {form.title}

          <p
            style={{
              fontFamily: 'Aeroportal-Bold',
              fontSize: '33px',
              color: 'white',
              width: '380px',
              textAlign: 'left',
              lineHeight: 'normal',
              marginTop: '20px',
            }}
          >
            {getFormattedDate()}
          </p>
        </p>

        <div
          style={{
            position: 'absolute',
            top: '50px',
            left: '480px',
            width: '480px',
            height: '130px',
          }}
        >
          <p
            style={{
              fontFamily: 'Aeroportal',
              fontSize: '28px',
              color: 'white',
              textAlign: 'left',
              lineHeight: 'normal',
              position: 'absolute',
              bottom: 0,
              marginBottom: 0,
            }}
          >
            {form.description}
          </p>
        </div>

        <p
          style={{
            fontFamily: 'Aeroportal-Medium',
            fontSize: '22px',
            color: 'white',

            position: 'absolute',
            top: '295px',
            left: '710px',
            width: '300px',
            textAlign: 'left',
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
            opacity: 0.8,
          }}
        >
          {speakerText}
        </p>

        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '22px',
            color: 'white',

            position: 'absolute',
            top: '325px',
            left: '710px',
            width: '300px',
            textAlign: 'left',
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
          }}
        >
          {form.speakerName}
        </p>

        <p
          style={{
            fontFamily: 'Aeroportal-Medium',
            fontSize: '22px',
            color: 'white',

            position: 'absolute',
            top: '355px',
            left: '710px',
            width: '300px',
            textAlign: 'left',
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
            opacity: 0.8,
          }}
        >
          {form.speakerPosition}
        </p>

        {form.speakerPhoto && (
          <div
            style={{
              position: 'absolute',
              top: '240px',
              height: '180px',
              width: '180px',
              left: '480px',
              overflow: 'hidden',
              background: 'white',
              borderRadius: '300px',
            }}
            key={form.speakerPhoto.name}
          >
            <img
              alt="Photoframe"
              src={URL.createObjectURL(form.speakerPhoto)}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '180px',
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const ContentPhotoFrameAtCongress = () => {
    const width = '980px';

    if (form.photoFrame) {
      return (
        <div className="content">
          <div
            style={{
              position: 'absolute',
              top: '20px',
              height: '480px',
              left: '22px',
              width,
              overflow: 'hidden',
            }}
          >
            <img
              alt="Photoframe"
              src={URL.createObjectURL(form.photoFrame)}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width,
              }}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  const ContentSympossium = () => {
    const left = '74px',
      width = '400px';

    const buttonText = {
      es: 'Inscríbete',
      en: 'Sign Up!',
      de: 'Anmelden',
      fr: 'S’enregistrer',
    }[form.lang];

    return (
      <div className="content">
        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '60px',
            color: 'white',

            position: 'absolute',
            top: '80px',
            left,
            width,
            textAlign: 'left',
            lineHeight: 'normal',
          }}
        >
          {form.title}

          <p
            style={{
              fontFamily: 'Aeroportal-Medium',
              fontSize: '24px',
              color: 'white',

              marginTop: '20px',
              width,
              textAlign: 'left',
              lineHeight: '24px',
            }}
          >
            {getDayOfWeek()}

            <p
              style={{
                fontFamily: 'Aeroportal-Bold',
                fontSize: '36px',
                color: 'white',

                marginTop: '0px',
                width,
                textAlign: 'left',
                lineHeight: '44px',
              }}
            >
              {getFormattedDate()}

              <p
                style={{
                  fontFamily: 'Aeroportal-Medium',
                  fontSize: '24px',
                  color: 'white',

                  marginTop: '30px',
                  width,
                  textAlign: 'left',
                  lineHeight: '24px',

                  opacity: 0.8,
                }}
              >
                {form.description}

                <p
                  style={{
                    fontFamily: 'Aeroportal-Bold',
                    fontSize: '23px',
                    color: 'white',

                    position: 'relative',
                    top: '20px',
                    left: '185px',
                    width: '190px',
                    textAlign: 'center',
                    lineHeight: 'normal',

                    borderColor: 'white',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '100px',
                    padding: '24px 10px',
                  }}
                >
                  {buttonText}
                </p>
              </p>
            </p>
          </p>
        </p>
      </div>
    );
  };
  const ContentClinicalCase = () => {
    const left = '110px',
      width = '300px';

    const text = {
      es: 'Caso clínico',
      en: 'Clinical case',
      de: 'Klinischer Fall',
      fr: 'Cas clinique',
    }[form.lang];

    console.log('form', form.lang, text);

    return (
      <div className="content">
        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '30px',
            color: 'white',

            position: 'absolute',
            top: '29px',
            left: '115px',
            width: '40px',
            textAlign: 'left',
            lineHeight: '28px',
          }}
        >
          {text}
        </p>
        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '40px',
            color: 'white',

            position: 'absolute',
            top: '185px',
            left,
            width,
            textAlign: 'left',
            lineHeight: 'normal',
          }}
        >
          {form.title}

          <p
            style={{
              fontFamily: 'Aeroportal-Bold',
              fontSize: '24px',
              color: 'white',

              marginTop: '30px',
              width,
              textAlign: 'left',
              lineHeight: '24px',

              opacity: 0.8,
            }}
          >
            {form.description}
          </p>
        </p>
      </div>
    );
  };
  const ContentTextPhoto = () => {
    const width = '580px',
      left = '55px';

    if (form.photoFrame) {
      var img = document.createElement('img');
      img.src = URL.createObjectURL(form.photoFrame);
      img.onload = () => {
        if (
          dimensions.width !== img.width ||
          dimensions.height !== img.height
        ) {
          setDimensions({ width: img.width, height: img.height });
        }
      };
    }
    const ratio = dimensions.width / dimensions.height;
    const MAGIC_RATIO = 580 / 450;
    const adjustVertical = ratio < MAGIC_RATIO;

    return (
      <div className="content">
        <p
          style={{
            fontFamily: 'Aeroportal-Bold',
            fontSize: '38px',
            color: 'white',

            position: 'absolute',
            top: '130px',
            left,
            width: '330px',
            textAlign: 'left',
            lineHeight: '40px',
          }}
        >
          {form.title}

          <div
            style={{
              position: 'relative',
              top: '25px',
              left: '5px',
              width: '52px',
              borderBottom: '2px solid white',
              opacity: 0.7,
            }}
          />

          <p
            style={{
              fontFamily: 'Aeroportal-Medium',
              fontSize: '22px',
              color: 'white',
              marginTop: '70px',
              textAlign: 'left',
              lineHeight: '25px',
            }}
          >
            {form.description}
          </p>
        </p>

        {form.photoFrame ? (
          <div
            style={{
              position: 'absolute',
              top: '50px',
              left: '400px',
              width,
              height: '450px',
              overflow: 'hidden',
            }}
          >
            {adjustVertical ? (
              <img
                alt="Photoframe"
                src={URL.createObjectURL(form.photoFrame)}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '580px',
                }}
              />
            ) : (
              <img
                alt="Photoframe"
                src={URL.createObjectURL(form.photoFrame)}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '460px',
                }}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const template =
    (product
      ? modelEntity.templates[product]
      : modelEntity.templates['default']) ?? modelEntity.templates['default'];

  const content = (
    <>
      {modelEntity.name === 'Congress' ? <ContentCongress /> : <></>}

      {modelEntity.name === 'Congress Presentation' ? (
        <ContentCongressPresentation />
      ) : (
        <></>
      )}
      {modelEntity.name === 'Photo frame at congress' ? (
        <ContentPhotoFrameAtCongress />
      ) : (
        <></>
      )}

      {modelEntity.name === 'Sympossium / Own meeting' ? (
        <ContentSympossium />
      ) : (
        <></>
      )}

      {modelEntity.name === 'Clinical Case Publication' ? (
        <ContentClinicalCase />
      ) : (
        <></>
      )}

      {modelEntity.name === 'Text + photo (new product, web...)' ? (
        <ContentTextPhoto />
      ) : (
        <></>
      )}

      <img className="bg" src={template} alt={modelEntity.name} />
    </>
  );

  if (isThumbnailPreview) {
    return <div id="canvas_preview">{content}</div>;
  } else return <div id="canvas_comp">{content}</div>;
};
