import { Body } from './Body';
import { Footer } from '../../ui/Footer';
import { Header } from '../../ui/Header';

export const HomeScreen = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
};
