export const Button = ({
  onPress,
  label,
}: {
  onPress: () => void;
  label: string;
}) => {
  return (
    <div className="downloadButton" onClick={onPress}>
      {label}
    </div>
  );
};
