import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { SettingsProvider } from './contexts/SettingsContext';
import { HomeScreen } from './screens/Home/HomeScreen';

function App() {
  return (
    <div className="App">
      <SettingsProvider>
        <HomeScreen />
      </SettingsProvider>
    </div>
  );
}

export default App;
