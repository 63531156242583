import data from './data';
import { AreaName } from './types';

export const getArea = (area: AreaName) => {
  return data.find((item) => item.name === area);
};

export const getAreas = () => {
  return data;
};

export const getAreaProducts = (area: AreaName) => {
  const activeArea = getArea(area);
  return activeArea?.products ?? [];
};

export const getProductModels = (area: AreaName, product?: string) => {
  const activeArea = getArea(area);
  const products = getAreaProducts(area);

  const activeProduct = product
    ? products.find((item) => item.name === product)
    : undefined;

  return activeProduct?.models ?? activeArea?.models ?? [];
};

export const toBase64 = async (file: File) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file);
  });
};
