import { useSettings } from '../../contexts/SettingsContext';
import { AreaEntity } from '../../data/types';
import { getAreas } from '../../data/utils';

export const AreaSelector = () => {
  const { area, setArea } = useSettings();
  const areas = getAreas();

  return (
    <>
      {areas.map((item, index) => (
        <Tag
          key={item.name}
          area={item}
          isPressed={area === item.name}
          onPress={() => setArea(item.name)}
        />
      ))}
    </>
  );
};

const Tag = ({
  area,
  isPressed,
  onPress,
}: {
  area: AreaEntity;
  isPressed: boolean;
  onPress: () => void;
}) => {
  return (
    <label
      className={`tag ${isPressed && 'active'}`}
      onClick={onPress}
      style={isPressed ? { background: area.color } : undefined}
    >
      <img src={area.icon} alt="logo" />
      {area.name}
    </label>
  );
};
