import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { AreaSelector } from './AreaSelector';
import { ProductSelector } from './ProductSelector';
import { ModelSelector } from './ModelSelector';
import { Preview } from './Preview';
import { FormContent } from './FormContent';
import { ReactNode, useMemo } from 'react';
import { Button } from '../../ui/Button';
import { usePDF } from './usePDF';
import { useCanvas } from './useCanvas';
import { Canvas } from './Canvas';
import { useSettings } from '../../contexts/SettingsContext';

export const Body = () => {
  const secondCol = { offset: 0, span: 3 };
  const { area } = useSettings();

  const AccordionDom = useMemo(
    () => (
      <Accordion defaultActiveKey="0" flush>
        <AccordionItem
          eventKey={0}
          label="Select Area"
          content={<AreaSelector />}
        />
        <AccordionItem
          eventKey={1}
          label="Select Product"
          content={<ProductSelector />}
          disabled={area === 'Corporate'}
        />
        <AccordionItem
          eventKey={2}
          label="Select Model"
          content={<ModelSelector />}
        />
        <AccordionItem
          eventKey={3}
          label="Fill Content"
          content={<FormContent />}
        />

        <AccordionItem
          eventKey={4}
          label="Download"
          content={<DownloadButtons />}
        />
      </Accordion>
    ),
    [area]
  );

  return (
    <>
      <Container className="App-body">
        <Row className="App-body">
          <Col md={12} lg={{ span: 6, offset: 1 }}>
            {AccordionDom}
          </Col>

          <Col sm={0} md={secondCol}>
            <div className="d-xs-none d-sm-block positionFixed">
              <Preview />
            </div>
          </Col>
        </Row>
      </Container>
      <Canvas />
    </>
  );
};

const AccordionHeaderLabel = ({
  index,
  label,
  disabled = false,
}: {
  index: number;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <label className={'AccordionHeaderLabel' + (disabled ? ' disabled' : '')}>
      {`${index}. ${label}`}
      <div className="line" />
    </label>
  );
};

const AccordionItem = ({
  eventKey,
  label,
  content,
  disabled = false,
}: {
  eventKey: number;
  label: string;
  content: ReactNode;
  disabled?: boolean;
}) => {
  if (disabled) {
    return (
      <div className="disabled accordion-item">
        <AccordionHeaderLabel
          index={eventKey + 1}
          label={label}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <Accordion.Item eventKey={`${eventKey}`}>
      <Accordion.Header aria-disabled={disabled}>
        <AccordionHeaderLabel
          index={eventKey + 1}
          label={label}
          disabled={disabled}
        />
      </Accordion.Header>
      <Accordion.Body>{content}</Accordion.Body>
    </Accordion.Item>
  );
};

const DownloadButtons = () => {
  const { openPDF } = usePDF();
  const { saveCanvas } = useCanvas();

  const downloadJPEG = async () => {
    await saveCanvas();
  };
  const downloadPDF = async () => {
    await openPDF();
  };

  return (
    <div className="buttons">
      <Button label="Download JPEG" onPress={downloadJPEG} />
      <Button label="Download PDF" onPress={downloadPDF} />
    </div>
  );
};
